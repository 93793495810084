<template>
    <div class="order-chart__wrap">
        <div class="chart-title">Fulfilled orderrs</div>
        <ve-line :data="chartData"
            height="500px"
            :settings="chartSettings"
            :extend="chartExtend">
        </ve-line>
    </div>
</template>
<script>
/* eslint-disable */
import Vue from 'vue'
import VCharts from 'v-charts'
import echarts from 'echarts'

Vue.use(VCharts)
Vue.use(echarts)

export default {
    data () {
        this.chartSettings = {
            stack: { '用户': ['访问用户', '下单用户'] },
            area: true
        }
        return {
            loading: false,
            chartData: {
                columns: ['month', 'Sells', 'Cost'],
                rows: [
                    { month: 'January', Sells: 220, Cost: 100 },
                    { month: 'February', Sells: 330, Cost: 410 },
                    { month: 'March', Sells: 280, Cost: 390 },
                    { month: 'April', Sells: 260, Cost: 355 },
                    { month: 'May', Sells: 290, Cost: 325 },
                    { month: 'June', Sells: 251, Cost: 340 },
                    { month: 'July', Sells: 310, Cost: 520 },
                    { month: 'August', Sells: 300, Cost: 280 },
                    { month: 'September', Sells: 285, Cost: 300 },
                    { month: 'October', Sells: 350, Cost: 410 },
                    { month: 'November', Sells: 305, Cost: 356 },
                    { month: 'December', Sells: 600, Cost: 480 }
                ]
            },
            chartExtend: {
                legend: {
                    icon: 'circle',
                    top: '2%',
                    right: '3%',
                    pageIconSize: 8,
                },
                grid: {
                    top: '20%',
                    left: '2%',
                    right: '3%',
                    bottom: '10%',
                    containLabel: true,
                },
                tooltip: {
                    trigger: 'axis',
                    backgroundColor: '#fff',
                    padding: [10, 15, 10, 15],
                    extraCssText: 'width:140px;box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);',
                    textStyle: {
                        color: 'rgba(45, 45, 45, 1.0)',
                        fontSize: '12'
                    },
                    formatter: (params) => {
                        // debugger
                        const valueStrs = [`<span style="display: inline-block; font-size: 12px; color: rgba(45, 45, 45, .8); margin-bottom: 5px;">${params[0] && params[0].name}</span>`]
                        params.map(p => {
                            valueStrs.push(`${p.marker}
                                <div style="display: inline-block; font-size: 14px; width: 50%; color: #2d2d2d; font-weight: 400;">${p.seriesName}</div>
                                <div style="display: inline-block; font-size: 14px; width: 35%; text-align: right; color: #2d2d2d; font-weight: 500; font-family: HelveticaNeue, HelveticaNeue-Medium;">${p.value[1]}</div>`
                            )
                        })
                        return valueStrs.join('<br/>')
                    }
                },
                "xAxis.0.boundaryGap": false,
                "series.0.smooth": false,
                "series.0.symbol": "circle",
                "series.0.color": "#5B8FF9",
                "series.0.symbolSize:": "5",
                "series.0.areaStyle": {
                    normal: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                            offset: 0,
                            color: '#5B8FF9'
                        }, {
                            offset: 1,
                            color: 'rgba(255,255,255,0)'
                        }])
                    }
                },
                "xAxis.1.boundaryGap": false,
                "series.1.smooth": false,
                "series.1.symbol": "circle",
                "series.1.color": "#FFBC00",
                "series.1.symbolSize:": "5",
                "series.1.areaStyle": {
                    normal: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                            offset: 0,
                            color: '#FFBC00'
                        }, {
                            offset: 1,
                            color: 'rgba(255,255,255,0)'
                        }])
                    }
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.order-chart__wrap {
    position: relative;
    // min-height: calc(#{$mainHeight} - #{$headerHeight} - #{$footerHeight} - 230px);
    .chart-title{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: 9px 24px 14px 24px;
        font-size: 18px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        color: #1a1a1a;
        border-bottom: 1px solid #eaeaea;
    }
    .ve-line {
        height: 100% !important;
    }
}
</style>