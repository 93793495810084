<template>
    <div class="dashboard-wrap">
        <div class="main-content-wrap">
            <div class="title-wrap">
                <span class="title">Overview</span>
                <div class="select-date">
                    <span class="label">Time</span>
                    <el-select v-model="seletDate" size="small">
                        <el-option label="Today" value="1"></el-option>
                        <el-option label="Today1" value="3"></el-option>
                        <el-option label="Today2" value="4"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="order-wrap">
                <div class="item"
                    v-for="(item, index) in orderDatas"
                    :key="index">
                    <img class="img-logo" :src="item.img" alt="">
                    <div class="number-wrap">
                        <div class="number">{{ item.number }}</div>
                        <div class="name">{{ item.name }}</div>
                    </div>
                </div>
            </div>
            <order-chart></order-chart>
        </div>
    </div>
</template>

<script>
import OrderChart from './components/order-chart.vue'
export default {
    components: {
        OrderChart
    },
    data () {
        return {
            seletDate: '',
            orderDatas: [
                {
                    img: require('@/assets/rap/order-icon.png'),
                    name: 'Order',
                    number: '4756'
                },
                {
                    img: require('@/assets/rap/revenue-icon.png'),
                    name: 'Revenue',
                    number: '5654.655'
                },
                {
                    img: require('@/assets/rap/costs-icon.png'),
                    name: 'Costs',
                    number: '235.866'
                },
                {
                    img: require('@/assets/rap/profit-icon.png'),
                    name: 'Profit',
                    number: '4756'
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>

.dashboard-wrap{
    box-sizing: border-box;
    padding: 25px 0;
    background: #f4f5f7;
    min-height: calc(#{$mainHeight} - #{$headerHeight} - #{$footerHeight});
    .main-content-wrap{
        background: #ffffff;
        border: 1px solid $borderColor;
    }
    .title-wrap{
        height: 72px;
        border-bottom: 1px solid $borderColor;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 25px;
        .title{
            font-size: 18px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 500;
            color: #1a1a1a;
        }
        .select-date{
            width: 192px;
            height: 32px;
            display: flex;
            flex-direction: row;
            .label{
                display: inline-block;
                width: 62px;
                height: 30px;
                background: #fafafa;
                border: 1px solid #dedede;
                border-right-width: 0;
                border-radius: 2px 0px 0px 2px;
                text-align: center;
                line-height: 30px;
                font-size: 14px;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                color: #48494d;
            }
            /deep/ .el-select .el-input__inner{
                border-radius: 0px 2px 2px 0px;
            }
        }
    }
    .order-wrap{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 24px;
        .item{
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-grow: 1;
            &:not(:last-of-type) {
                border-right: 1px solid rgba(233,233,233,0.99);
            }
            &:not(:first-of-type){
                padding-left: 87px;
            }
            .img-logo{
                flex-shrink: 0;
                display: inline-block;
                width: 54px;
                height: 54px;
                margin-right: 15px;
            }
            .number{
                font-size: 32px;
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: 500;
                color: #1a1a1a;
                margin-bottom: 5px;
            }
            .name{
                opacity: 0.7;
                font-size: 14px;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                color: #4d4d4d;
            }
        }
    }
}
</style>
